<template lang="">
  <div>
    <v-chart class="w-full" style="height:250px" :option="lineOptions" autoresize />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, GaugeChart, BarChart, PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent,ToolboxComponent, GridComponent } from "echarts/components";
import VChart from "vue-echarts";

use([CanvasRenderer,LineChart,GaugeChart,BarChart,PieChart,TitleComponent,TooltipComponent,ToolboxComponent,GridComponent,LegendComponent]);

export default {
  props: {
    legendName : { type: String },
    dateRange: { type: Array },
    chartData: { type: Array },
    chartColor: { type: String }
  },
  created () {
    // this.lineOptions.series[0].name = this.legendName
    // this.lineOptions.series[0].data = this.chartData
    // this.lineOptions.series[0].itemStyle.color = this.chartColor
  },
  components: {
    "v-chart": VChart
  },
  computed: {
    lineOptions () {
      return {
        xAxis: {
          type: 'category',
          data: this.dateRange
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: this.chartData,
            itemStyle: {
              color: this.chartColor
            },
            name: this.legendName,
            type: 'line',
            lineStyle: {
              width: 4,
            },
          }
        ]
      }
    }
  },
  data () {
    return {
      
    }
  }
}
</script>
